@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap");

.auth-logo {
  display: block;
  /* Center it if necessary */
  margin: 0 auto 15px;
  /* Center horizontally */
  max-width: 90px;
  /* Adjust max width as needed */
  height: auto;
  cursor: pointer;
}

.error {
  display: flex;
  color: #da3c03;
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: 600;
  text-align: left;
}

.success {
  color: green;
  font-size: 12px;
  margin-top: 10px;
  font-size: medium;
  font-weight: 600;
  /* margin-bottom: 10px; */
}

.auth-screens-bg {
  /* background-image: url('/public/assets/images/login_signup.webp');
  background-size: cover;
  background-position: center; */
  background-color: #0cc0df;
  width: 100%;
  min-height: 100vh;
  /* Full height of the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.font-legal {
  font-family: "League Spartan", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.font-30px {
  font-size: 30px !important;
}

.font-25px {
  font-size: 25px !important;
}

.font-20px {
  font-size: 20px !important;
}
