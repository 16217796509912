
.marquee {
  white-space: 'nowrap';
  background-color: #0693e3;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  position: relative;

}

.marquee-content {
  color: lightcyan;
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  white-space: nowrap;
  animation: marquee 15s linear infinite;
}

.responsiveYouTubeIframe {
  position: 'static';
  padding-bottom: '56.25%';
  height: 0;
  overflow: 'hidden';
  max-width: '100%';
  justify-content: start;
  justify-items: start;
  display: flex;
  align-items: start;
  background-color: '#000';
}

.mobile-container {
  position: static;
  margin: 0 auto;
  width: 70%;
  object-fit: cover;

  /* object-position: inherit; */
  /* padding-top: 210%; */
}

.marquee-content2 {
  position: absolute;
  top: 75%;
  left: 0;
  color: #0781c7;
  padding: 10px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  animation: marquee 15s linear infinite;
}

@media (max-width: 768px) {

  .marquee-content {
    top: 8rem;
    width: 80%;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .marquee-content2 {
    top: 6rem;
    width: 80%;
    font-size: 16px;
  }
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

/* Content Styling */
.content {
  padding: 20px;
}