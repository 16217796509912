.howdoesitwork {
  /* background: hsla(189, 100%, 88%, 1);
  background: linear-gradient(
    360deg,
    hsla(189, 100%, 88%, 1) 0%,
    hsla(16, 96%, 90%, 1) 100%
  );
  background: -moz-linear-gradient(
    360deg,
    hsla(189, 100%, 88%, 1) 0%,
    hsla(16, 96%, 90%, 1) 100%
  );
  background: -webkit-linear-gradient(
    360deg,
    hsla(189, 100%, 88%, 1) 0%,
    hsla(16, 96%, 90%, 1) 100%
  ); */
  background: #fff;
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#C2F6FF", endColorstr="#FEDBCE", GradientType=1 );
  padding: 70px 0;
}

.howdoesitwork .hdwork {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  padding: 0;
}

.howdoesitwork .hdwork .header h2 {
  margin-bottom: 0;
}

.howdoesitwork .hdwork .header {
  width: 350px;
}

.header-circle {
  position: relative;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  padding: 35px;
  border-radius: 200px;
  /* border: 4px solid #dcdcdc;
    border-left: 4.95px solid transparent;
    border-top: 4.95px solid transparent; */
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.header-circle-outer {
  width: 280px;
  height: 280px;
  border-radius: 50%;
  border: 30px solid transparent;
  background-size: 100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(white, white),
    linear-gradient(45deg, #0cc0df 50%, #737373 0%),
    linear-gradient(135deg, #ff895d 50%, lightgrey 0%);
  background-position: center center, left top, right top, left bottom,
    right bottom;
  background-origin: content-box, border-box, border-box, border-box, border-box;
  background-clip: content-box, border-box, border-box, border-box, border-box;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.header-circle-inner {
  background: hsla(0, 0%, 85%, 1);
  background: linear-gradient(
    135deg,
    hsla(0, 0%, 85%, 1) 0%,
    hsla(0, 0%, 100%, 1) 100%
  );
  background: -moz-linear-gradient(
    135deg,
    hsla(0, 0%, 85%, 1) 0%,
    hsla(0, 0%, 100%, 1) 100%
  );
  background: -webkit-linear-gradient(
    135deg,
    hsla(0, 0%, 85%, 1) 0%,
    hsla(0, 0%, 100%, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#F7F7F7", endColorstr="#FFFFFF", GradientType=1);
  box-shadow: 3px 0 4px 0px #787878;
  width: 225px;
  height: 225px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding: 0 30px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.header-circle .indicator {
  position: absolute;
  width: 25px;
  height: 25px;
  padding: 3px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 3px 0px #787878;
}

.header-circle .indicator.create {
  top: 100px;
  right: -5px;
}

.header-circle .indicator.invite {
  top: 230px;
  right: 2px;
}

.header-circle .indicator.select {
  bottom: 2px;
  left: 230px;
}

.header-circle .indicator.payconfirm {
  bottom: -5px;
  left: 100px;
}

.header-circle .indicator::after {
  content: "";
  display: block;
  width: 19px;
  height: 19px;
  border-radius: 50%;
}

.header-circle .indicator.create::after {
  background-color: #0cc0df;
}

.header-circle .indicator.invite::after {
  background-color: #737373;
}

.header-circle .indicator.select::after {
  background-color: #ff895d;
}

.header-circle .indicator.payconfirm::after {
  background-color: lightgrey;
}

.howdoesitwork .hdwork .steps {
  position: relative;
  /* width: calc(100% - 350px); */
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: -20px;
}

.howdoesitwork .hdwork .step {
  position: relative;
  width: max-content;
  /* max-width: 630px; */
}

.howdoesitwork .hdwork .step::before {
  /* content: ''; */
  display: block;
  position: absolute;
  width: 100px;
  height: 4px;
  background-color: #dcdcdc;
}

.howdoesitwork .hdwork .step.create,
.howdoesitwork .hdwork .step.payconfirm {
  left: -90px;
}

.howdoesitwork .hdwork .step .desc {
  display: block;
  padding: 15px 30px 20px 45px;
  border-radius: 70px;
  text-align: left;
  color: #fff;
  min-width: 380px;
}

.howdoesitwork .hdwork .step .desc h2 {
  font-size: 20px;
}

.howdoesitwork .hdwork .step .num {
  position: absolute;
  top: 50%;
  width: 60px;
  height: 60px;
  left: -30px;
  padding: 20px;
  transform: translateY(-50%);
  font-size: 30px;
  line-height: 0.65;
  font-weight: 700;
  background: hsla(0, 0%, 75%, 1);
  background: linear-gradient(
    135deg,
    hsla(0, 0%, 75%, 1) 0%,
    hsla(0, 0%, 100%, 1) 100%
  );
  background: -moz-linear-gradient(
    135deg,
    hsla(0, 0%, 75%, 1) 0%,
    hsla(0, 0%, 100%, 1) 100%
  );
  background: -webkit-linear-gradient(
    135deg,
    hsla(0, 0%, 75%, 1) 0%,
    hsla(0, 0%, 100%, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#F7F7F7", endColorstr="#FFFFFF", GradientType=1);
  border-radius: 50%;
  box-shadow: 3px 0 4px 0px #787878;
}

.howdoesitwork .hdwork .step .desc p {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
}

/* 
.howdoesitwork .hdwork .step.create {
    top: -75px;
    left: -10px;
}

.howdoesitwork .hdwork .step.invite {
    top: 55px;
    left: 50px;
}
.howdoesitwork .hdwork .step.select {
    bottom: 55px;
    left: 50px;
}

.howdoesitwork .hdwork .step.payconfirm {
    bottom: -75px;
    left: -10px;
} */

.howdoesitwork .hdwork .step.create .desc {
  background-color: #0cc0df;
}

.howdoesitwork .hdwork .step.invite .desc {
  background-color: #737373;
}

.howdoesitwork .hdwork .step.select .desc {
  background-color: #ff895d;
}

.howdoesitwork .hdwork .step.payconfirm .desc {
  background-color: lightgrey;
}

@media screen and (max-width: 768px) {
  .howdoesitwork {
    padding: 20px 0;
  }
  .howdoesitwork .hdwork {
    padding: 10px 0 50px;
    flex-direction: column;
    gap: 30px;
  }

  .header-circle {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 250px;
    height: 250px;
    padding: 0;
    margin: 0 auto;
    border: 0;
  }

  .header-circle-outer {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    width: 250px;
    height: 250px;
  }

  .header-circle-inner {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    width: 195px;
    height: 195px;
  }

  .header-circle .indicator {
    display: none;
  }

  .howdoesitwork .hdwork .header {
    overflow: hidden;
  }

  .howdoesitwork .hdwork .header h1 {
    font-size: 1.5em;
  }

  .howdoesitwork .hdwork .header,
  .howdoesitwork .hdwork .steps {
    width: 100%;
    left: 0px;
  }

  .howdoesitwork .hdwork .steps .step {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .howdoesitwork .hdwork .step.create,
  .howdoesitwork .hdwork .step.payconfirm {
    left: 0;
  }

  .howdoesitwork .hdwork .step .num {
    width: 50px;
    height: 50px;
    left: 15px;
    font-size: 21px;
    line-height: 1;
    padding: 15px;
  }

  .howdoesitwork .hdwork .step .desc {
    min-width: 0;
    width: 350px;
    /* padding-left: 80px; */
  }

  .howdoesitwork .hdwork .step .desc h2 {
    font-size: 1.15rem;
  }

  .howdoesitwork .hdwork .step .desc p {
    font-size: 0.85rem;
    line-height: 1.45;
  }
}
