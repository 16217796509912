h2,
h3,
h4 {
    margin: 1px;
    padding: 0;
}

.rsvp-invitation-container {
    width: 100vw;
    min-height: 100vh;
    position: relative;
    background-image: url(../../../public/assets/images/invbg.jpg);
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
}

.rsvp-invitation-inner {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 6px;
    width: calc(100% - 30px);
    height: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 10px 5px;
}

.rsvp-invitation-form,
.rsvp-invitation-card {
    position: relative;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8d6306;
    z-index: 9;
}

.invpat {
    position: absolute;
    width: 33%;
}

.invpat.invleftb,
.invpat.invrightb {
    width: 22%;
}

.invpat.invleft {
    left: 0;
    top: 0
}

.invpat.invleftb {
    left: 0;
    bottom: 0
}

.invpat.invright {
    right: 0;
    top: 0
}

.invpat.invrightb {
    right: 0;
    bottom: 0
}

.rsvp-invitation-head {
    font-size: 1.5rem;
    font-family: "Crimson Text", serif;
    font-weight: 600;
}

.rsvp-invitation-head .event-name {
    font-size: 2.5rem;
    font-family: "Pinyon Script", cursive;
    font-weight: 600;
    font-style: oblique;
    /* margin-top: 5px; */
}

.sub-heading{
    color: #5A773F;
    font-weight: 600;
}

.inviteby,
.datetime,
.location {
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    margin-top: 8px;
    line-height: 20px;
    font-family: "Crimson Text", serif;
    font-weight: 600;
}

.inviteby i,
.datetime i,
.location i {
    font-weight: 400;
}

.rsvp-form {
    display: flex;
    flex-direction: column;
    gap:4px;
    width: 100%;
    max-width: 420px;
    /* margin-top: 5px; */
    color: #8d6306;
    text-align: left;
    padding: 8px 0;
}

.rsvp-invitation-form h3{
    font-size: 1.25rem;
    font-family: "Crimson Text", serif;
    font-weight: 600;
}

.rsvp-form label {
    font-family: "Crimson Text", serif;
    display: inline-block;
    font-size: 1.25rem;
    color: #8d6306;
    font-weight: 500;
    margin-bottom: 5px;
}

.rsvp-input {
    width: 100%;
    /* padding: 0px 0px; */
    padding-bottom: 5px;
    border-radius: 0;
    border: 0;
    background-color: transparent;
    border-bottom: 1.5px solid #8d6306;
}

.rsvp-input::-webkit-input-placeholder{
    font-family: "Crimson Text", serif;
    font-size: 1.25rem;
    color: #8d6306;
}

.rsvp-input:focus,
.rsvp-input:focus-visible {
    outline: 0;
    border-color: #8d6306;
}

.rsvp-textarea {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #8d6306;
    height: 100px;
}

.rsvp-error {
    color: red;
    font-size: 10px;
}

.rsvp-submit-button {
    background-color: #8d6306;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 0;
    cursor: pointer;
    font-family: "Crimson Text", serif;
    font-size: 1.25rem;
}

.thank-you-card {
    background-color: #f8f9fa;
    /* Light background for the card */
    border: 1px solid #ccc;
    /* Border for the card */
    border-radius: 10px;
    /* Rounded corners */
    padding: 20px;
    /* Padding inside the card */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
    text-align: center;
    /* Center align the text */
    padding: 30px 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    height: 150px;
    /* Space above the card */
    max-width: 500px;
    /* Max width for the card */
    width: 100%;
    /* Full width */
}

.compliment{
    color: #8d6306;
    font-size: 1.55rem;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Crimson Text", serif;
    font-weight: 600;
    margin-top: 15px;
}
.compliment img{
    width: 60px;
    /* vertical-align: baseline; */
    margin-left: 10px;
}

@media screen and (max-width: 640px) {
    .rsvp-invitation-container {
        height: 100%;
        overflow: unset;
    }
}