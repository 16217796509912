.create-event-cta2-container {
  border-radius: 12px;
  overflow: hidden;
  text-align: center;
  background-color: #f8f8ff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04),
    6px 3px 14px 0px rgba(0, 0, 0, 0.04), 23px 12px 26px 0px rgba(0, 0, 0, 0.03),
    53px 27px 35px 0px rgba(0, 0, 0, 0.02),
    94px 48px 42px 0px rgba(0, 0, 0, 0.01);
}

.create-event-cta2-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  background-color: #0cc0df;
  padding: 8px;
}

.create-event-cta2-header h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  text-align: left;
}

.create-event-cta2-action-btn {
  padding: 10px;
}

.create-event-cta2-action-btn p {
  font-weight: 600; /* Slightly bold */
  line-height: 80%;
  text-align: center;
  color: #ff895d; /* Orange color */
}

.tab-container {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 100px;
  min-height: calc(100vh - 146px);
  z-index: 1;
}

.events-list .elevated-card.p-card {
  margin-top: 0;
  margin-bottom: 30px;
  overflow: unset;
}

.events-list .elevated-card .p-card-body {
  overflow-y: unset;
  overflow-x: hidden;
}

.event-form-inner {
  margin: 30px 0;
}

.event-form-body {
  max-width: none;
  display: grid;
  grid-template-columns: 3.75fr 1.25fr;
  gap: 30px;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 0 4px 1px #ddd;
  padding: 15px;
  text-align: left;
}

.event-form-body.alter {
  display: block;
  padding: 0;
}

.event-form-body.alter.full {
  border-radius: 0;
  box-shadow: none;
}

.event-form-body.list {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}

.event-form-body.list.full {
  display: block;
  padding: 0;
}

.event-form-body.list.popup {
  grid-template-columns: 3.5fr 1.5fr;
  gap: 30px;
}

.event-form-body.list .navi {
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.event-form-body.list h1 {
  font-size: 1.25rem;
  line-height: 1.75;
  margin-top: 0;
  text-align: center;
}

.event-form-body.list2 {
  align-items: start;
  grid-template-columns: 3.75fr 1.25fr;
  gap: 60px;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}

.event-form-body.alter .event-service-head > div:not(.pi) {
  flex-direction: column;
  align-items: flex-start;
}

.event-form-body.alter .event-service-head > div:not(.pi) > div {
  display: flex;
  gap: 10px;
  font-size: 1em;
  color: #353755;
  font-weight: 600;
  align-items: flex-start;
  text-transform: capitalize;
}

.event-form-head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04),
    6px 3px 14px 0px rgba(0, 0, 0, 0.04), 23px 12px 26px 0px rgba(0, 0, 0, 0.03),
    53px 27px 35px 0px rgba(0, 0, 0, 0.02),
    94px 48px 42px 0px rgba(0, 0, 0, 0.01);
  background: #f8f8ff;
  border-radius: 12px;
  margin-bottom: 30px;
  overflow: hidden;
}

.event-form-head.banner {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 35px;
  min-height: 230px;
  height: auto;
}

.event-form-head.banner .banner-content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: #fff;
}

.event-form-body.list .event-form-head.banner .banner-content h1 {
  font-size: 2.25rem;
  line-height: 120%;
}

.event-form-body.list .event-form-head.banner .banner-content .sub-head {
  font-size: 0.975rem;
  line-height: 120%;
  font-weight: 600;
  opacity: 0.95;
  max-width: 640px;
  margin: 0 auto;
}

.event-form-head.banner::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #354955;
  opacity: 0.65;
  /* background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(248,248,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(248,248,255,1) 100%);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(248,248,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f8f8ff",GradientType=1); */
  bottom: 0;
  left: 0;
}

.event-service-head-inner {
  position: relative;
  z-index: 2;
}

.event-form-head h2 {
  margin: 0;
  text-align: left;
  font-size: 25px;
  font-weight: 600;
  color: #0cc0df;
}

.btns {
  text-align: right;
}

/* .btns>button {
  width: 202.13px;
} */

.btns.group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

form.event-form-inner .btns.group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
}

.btns.group > button {
  width: auto;
}

.custom-button.cancel,
.btns.group > button.cancel {
  background-color: #ff895d;
  border-color: #ff895d;
}

.custom-button.cancel:hover,
.btns.group > button.cancel:hover {
  background-color: #e27852;
  border-color: #e27852;
}

.event-chantgpt-fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.event-nodata-fields {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

.event-nodata-fields > div,
.event-nodata-fields > button {
  width: calc(50% - 7.5px);
}

.event-providers .event-service:not(.alter) {
  border-right: 0 !important;
  cursor: auto;
  border-radius: 12px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #f8f8ff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04),
    6px 3px 14px 0px rgba(0, 0, 0, 0.04), 23px 12px 26px 0px rgba(0, 0, 0, 0.03),
    53px 27px 35px 0px rgba(0, 0, 0, 0.02),
    94px 48px 42px 0px rgba(0, 0, 0, 0.01);
  padding: 20px;
  border: none;
  cursor: pointer;
}

.event-providers .plicon {
  font-size: 20px;
  margin-top: 5px;
  /* background-color: #c2f6ff; */
  padding: 2px;
  border-radius: 2px;
}

.chatgpt-cont {
  box-shadow: 0 0 4px 1px #ddd;
  padding: 15px;
  border-radius: 12px;
}

.event-service-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.event-providerservcies {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.event-providerservcies .event-service,
.event-service-list .event-service {
  border-right: 0 !important;
  border-bottom: 0 !important;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid #0cc0df !important;
}

.event-providerservcies .event-service.sel-event,
.event-service-list .event-service.sel-event {
  border: 1px solid #ff895d !important;
}

.event-service-list.sidebar {
  position: relative;
}

.event-service-list.sidebar .sidebar-inner {
  position: sticky;
  position: -webkit-sticky;
  top: 130px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.event-service-list.sidebar .event-service {
  padding: 10px 15px;
}

.event-form-content .event-service-list.sidebar .event-service {
  max-width: 460px;
  margin: 0 auto 1em;
}

.event-service-list.sidebar .event-service .event-service-head {
  color: #383838;
}

.event-service-list.sidebar .event-service .event-service-head h3 {
  font-size: 1.0175rem;
  font-weight: 600;
}

.event-service-list.sidebar .event-service:nth-child(odd),
.event-service-list.sidebar .event-service:nth-child(even):hover {
  background-color: #fff;
  /* box-shadow: #0cc0df 0px 2px 8px 0px; */
  border: none !important;
  /* border-color: #0cc0df !important; */
}

.event-service-list.sidebar .event-service:nth-child(even),
.event-service-list.sidebar .event-service:nth-child(odd):hover {
  background-color: #fff;
  /* box-shadow: #ff895d 0px 2px 8px 0px; */
  border: none !important;
  /* border-color: #ff895d !important; */
}

.event-providerservcies .event-service {
  width: calc(33.33% - 10.125px);
  justify-content: space-between !important;
  padding: 0;
  gap: 0;
}

.event-providerservcies.full {
  width: 100%;
  max-width: 190px;
  padding-left: 20px;
  border-left: 1px solid #ddd;
}

.event-providerservcies.full .event-service {
  width: 100%;
  border: 0 !important;
}

.event-providerservcies .event-service .event-service-headtop {
  width: 100%;
  padding: 15px;
}

.event-providerservcies.full .event-service .event-service-headtop {
  padding: 0;
}

.event-providerservcies .event-service .event-service-headtop h3 {
  font-size: 1.125rem;
  line-height: 21px;
}

.event-providerservcies .event-service .event-service-headtop .price {
  color: #8f8f8f;
}

.event-providerservcies .event-service .event-service-headtop .desc {
  font-size: 15px;
  color: #8f8f8f;
  margin-top: 10px;
}

.event-providerservcies .event-service .event-service-headtop .validupto {
  font-size: 12px;
  color: #8f8f8f;
}

.event-providerservcies .event-service .event-service-headtop .price strong {
  color: #ff895d;
  font-size: 1.65rem;
  margin-right: 5px;
}

.event-providerservcies .event-service .event-service-middle {
  width: 100%;
  padding: 10px 15px;
  border-top: 1px solid #0cc0df;
  text-align: left;
}

.event-providerservcies.full .event-service .event-service-middle {
  border: 0;
  padding: 10px 0 0;
}

.event-providerservcies .event-service.sel-event .event-service-middle {
  border-top-color: #ff895d;
}

.event-providers .event-service:not(.alter):last-child {
  margin: 0;
}

.event-providers .event-service:not(.alter):hover {
  background-color: #f8f8ff;
}

.event-providers .event-service:not(.alter) .event-service-head {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.event-providers .event-service:not(.alter) .event-service-head h3 {
  margin: 0;
}

.event-providers .event-service:not(.alter) .event-service-head > div:not(.pi) {
  font-size: 15px;
  line-height: 24px;
  gap: 5px;
  align-items: flex-start;
}

.event-providers
  .event-service:not(.alter)
  .event-service-head
  > div.event-service-headtop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-providers
  .event-service:not(.alter)
  .event-service-head
  .event-service-headtop
  > div:not(.pi) {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.event-providers .event-service:not(.alter) .bottom .plicon {
  margin-top: 0;
  background-color: #fff;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: transparent;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: transparent;
  border-color: #0cc0df;
  color: #0cc0df;
}

.p-dialog .event-form.map {
  padding: 0;
  border: 0;
  box-shadow: none;
  width: 90vw;
  max-width: none;
  display: grid;
  grid-template-columns: 3fr 1.5fr;
  gap: 30px;
}

.p-dialog .event-form h2 {
  display: none;
}

.p-dialog .p-dialog-header {
  padding: 1.5rem 1.5rem;
}

.p-dialog .p-dialog-header .p-dialog-title {
  color: #0cc0df;
  /* max-width: 418px; */
}

.p-tabview-panel {
  padding: 0px;
  /* Adjust the padding inside each tab panel */
}

.p-tabview .p-tabview-nav {
  background-color: transparent;
}

.p-tabview .p-tabview-panels {
  background-color: transparent;
  padding: 1.25rem 0;
}

.events-container {
  text-align: start;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard.events-container {
  position: absolute;
  top: 12px;
  right: 0;
  z-index: 99;
}

.events-header {
  color: #ff895d;
  margin-bottom: 0px;
  margin-top: 4rem;
}

.events-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* .events-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(540px, 1fr));
  gap: 60px;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 30px;
} */

.event-card {
  max-width: 400px;
  flex: 1 1 calc(33.33% - 30px);
}

.elevated-card.p-card {
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  transition: transform 0.3s ease;
  margin-top: 15px;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
}

/* .elevated-card:hover {
  transform: scale(1.05);
} */

.elevated-card-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
}

.share-options {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.title-container {
  display: flex;
  /* Aligns items horizontally */
  align-items: center;
  /* Centers items vertically */
}

.MuiFormHelperText-root {
  margin: 0 !important;
}

.p-tabview-title > span:first-child {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  white-space: nowrap;
}

.p-tabview-title .badge {
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 50%;
  background-color: #ff895d;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.event-header {
  width: 100%;
  padding: 15px;
  border-radius: 3px 3px 0 0;
  background-color: #0cc0df;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-title {
  position: relative;
  font-size: 1.45rem;
  font-weight: 700;
  color: #fff;
  margin: 0;
}

.event-description {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
}

.event-body {
  border-top: 1px solid #c5c5c5;
}

.event-metainfo {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(569px, 1fr));
  border-bottom: 1px solid #c5c5c5;
}

.event-invite {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 15px;
}

.event-service:not(.alter) {
  cursor: pointer;
}

.event-service.active,
.event-service:not(.alter):hover {
  background-color: #c2f6ff;
}

.event-service.sel-event.active,
.event-service.sel-event:not(.alter):hover {
  background-color: #fedbce;
}

.event-service.alter {
  justify-content: flex-start;
}

.event-service.alter.no-data {
  display: block;
  text-align: center;
  border: 0;
  border-radius: 12px;
  background-color: #f8f8ff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04),
    6px 3px 14px 0px rgba(0, 0, 0, 0.04), 23px 12px 26px 0px rgba(0, 0, 0, 0.03),
    53px 27px 35px 0px rgba(0, 0, 0, 0.02),
    94px 48px 42px 0px rgba(0, 0, 0, 0.01);
}
.event-service.alter.no-data.alignleft {
  text-align: left;
}

.event-service.alter.no-data .MuiAlert-root {
  justify-content: center;
  align-items: center;
}

.event-service.alter.no-data h3 {
  font-size: 1.17em;
  margin-bottom: 0;
  font-weight: 600;
}

.event-service.alter.no-data h4 {
  font-size: 0.95em;
  font-weight: 500;
  margin-bottom: 15px;
}

.event-meta {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  border-right: 1px solid #c5c5c5;
  text-align: left;
}

.event-services {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(569px, 1fr));
}

.event-services .event-invite {
  justify-content: flex-start;
  border-top: 0;
}

.event-date,
.event-location {
  display: flex;
  gap: 10px;
  font-size: 1em;
  color: #353755;
  font-weight: 600;
  align-items: flex-start;
  text-transform: capitalize;
}

.event-cancel {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 9;
}

.event-cancel::before,
.event-cancel::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #ff895d;
  border-top-color: transparent;
  border-right-color: transparent;
}

.event-cancel::before {
  top: 0;
  left: 0;
}

.event-cancel::after {
  bottom: 0;
  right: 0;
}

.event-cancel span {
  position: absolute;
  display: block;
  width: 222px;
  padding: 5px 0;
  background-color: #ff895d;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  left: -40px;
  top: 30px;
  transform: rotate(45deg);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.event-edit {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-around;
  padding: 0 5px;
}

.event-middle {
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 15px;
}

.event-service {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
  gap: 20px;
  text-decoration: none;
  /* box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04),
    6px 3px 14px 0px rgba(0, 0, 0, 0.04), 23px 12px 26px 0px rgba(0, 0, 0, 0.03),
    53px 27px 35px 0px rgba(0, 0, 0, 0.02),
    94px 48px 42px 0px rgba(0, 0, 0, 0.01); */
  box-shadow: rgba(104, 104, 104, 0.15) 2.4px 2.4px 9.2px;
}

.event-service:nth-last-child(2),
.event-service:last-child {
  border-bottom: 0;
}

.event-service:nth-child(odd) {
  border-right: 1px solid #c5c5c5;
}

.event-service-head {
  width: 100%;
  font-size: 16px;
  color: #353755;
  font-weight: 600;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.event-service-head > div:not(.pi) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
}

.event-service.alter .event-service-head {
  justify-content: space-between;
}

.event-service-head-inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  text-align: left;
}

.event-service-head-inner .event-rsvp {
  margin-top: 0;
}

.event-service-head-inner h1 {
  text-align: left !important;
  margin: 1px;
  font-size: 1.17em;
  line-height: 1.5;
}

.event-service-head h3 {
  font-size: 1.175rem;
  font-weight: 600;
  text-align: left;
}

.event-service-body {
  position: relative;
  text-align: left;
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 30px;
}

.event-service-body h3 {
  margin-bottom: 15px;
}

.event-service-body > div {
  position: relative;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.65);
  z-index: 1;
}

.event-rsvp {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  gap: 10px;
  margin-top: 10px;
}

.share-invite-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.event-rsvp > span.vm,
.share-invite-container > span.vm {
  cursor: pointer;
  color: #ff895d;
}

.event-sep {
  padding: 7.5px 15px;
}

.event-sep::after {
  content: "";
  display: block;
  border-bottom: 2px dotted #ddd;
}

.p-card .p-card-title {
  margin: 0;
}

.p-card-content {
  padding: 0;
  height: 100%;
}

.elevated-card .p-card-body {
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  height: 100%;
}

.p-button.p-button-link:not(:disabled):focus {
  outline: 0;
  box-shadow: none;
}

.p-button.p-button-link:not(:disabled):hover .p-button-label {
  text-decoration: none;
}

.p-button.pickup {
  background: #ffff;
  color: #0cc0df;
}

.p-button.pickup:hover {
  background: #0cc0df;
  color: #ffffff;
}

@media (max-width: 763px) {
  .tab-container {
    padding-top: 120px;
  }

  .dashboard.events-container {
    position: relative;
    margin-bottom: 10px;
  }

  .event-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .event-edit {
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid #fff;
    padding: 15px 0 0;
  }

  .event-metainfo,
  .event-services,
  .event-body {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }

  .event-meta {
    border-right: 0;
    border-bottom: 1px solid #c5c5c5;
  }

  .event-service:nth-child(odd) {
    border-right: 0;
  }

  .event-service:nth-child(5) {
    border-bottom: 1px solid #c5c5c5;
  }

  .event-rsvp {
    flex-direction: column;
    align-items: flex-start;
  }

  .elevated-card-inner {
    flex-direction: column;
  }

  .event-service-head img {
    margin: 0 !important;
  }

  .p-tabview-title {
    white-space: unset;
  }

  .event-form-body,
  .event-form-head {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .event-form-body.list,
  .event-form-body.list2 {
    display: block;
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    order: 1;
    margin-bottom: 10px;
  }

  .btns.group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 10px;
  }

  .event-form-head.rev h2,
  .event-service-head-inner {
    order: 2;
  }

  .event-form-head.rev h2 {
    text-align: center;
  }

  .event-form-head.rev .event-service-head-inner {
    order: 1;
  }

  .event-form-head.rev .btns {
    margin-bottom: 0;
    order: 1;
  }

  /* .event-providers .event-service:not(.alter) {
    flex-direction: column;
  } */

  .event-providers .event-service:not(.alter) .event-service-map {
    display: none;
  }

  .event-providerservcies {
    margin-top: 15px;
  }

  .event-providerservcies .event-service {
    width: 100%;
  }

  .event-service-list {
    margin-top: 15px;
  }

  .event-service-list.sidebar {
    padding-top: 15px;
    border-top: 2px solid #353755;
  }

  .map-container {
    height: 60vh;
  }

  .event-form-body.list .event-form-head.banner .banner-content h1 {
    font-size: 1.75rem;
  }

  .event-service-data .event-service.alter .event-service-head {
    flex-direction: column;
  }

  .event-service-data .event-service.alter .event-service-head h3 {
    text-align: center;
  }
  .event-form-head.banner {
    height: 45vh;
    padding: 30px 20px;
  }
}
