

.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .forgot-password-card {
    background-color: #fff;
    padding: 0 20px 20px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
   
  }
  
  .forgot-password-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .forgot-password-button {
    width: 100%;
    padding: 10px;
    background-color: #0cc0df;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
  }
  
  .forgot-password-button:hover {
    background-color: #1da3ba;
  }
  
  /* .error {
    color: red;
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 10px;
  } */
  
  /* .message {
    color: green;
    font-size: 12px;
    margin-top: -5px;
    margin-bottom: 5px;
  } */
  