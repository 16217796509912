body {
  margin: 0;
  padding: 0;
}

.App {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  background-color: #ececf2;
  line-height: 140%;
  height: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.MuiMenuItem-root.menu-item {
  color: #353755;
  font-weight: 600;
}

p,
ul {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

p:first-child {
  margin-top: 0;
}

::-webkit-input-placeholder {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 1.1428571428571428rem;
  line-height: 1.4375em;
  color: rgba(0, 0, 0, 0.6);
}

button,
.p-component {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}

.p-inputtext {
  font-weight: 400;
  font-size: 1.1428571428571428rem;
  line-height: 1.4375em;
  color: rgba(0, 0, 0, 0.87);
  padding: 8.5px 14px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.frontend-container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  max-width: 1140px;
}

.event-form {
  width: 100%;
  min-width: 320px;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.event-form h2 {
  margin: 0;
  margin-bottom: 10px;
  color: #0cc0df;
  font-size: 1.25rem;
  line-height: 30px;
  font-weight: 600;
  text-align: left;
}

.p-button:not(.p-button-link) {
  color: #fff;
  background: #0cc0df;
  border: 1px solid #0cc0df;
  font-weight: 500;
  text-transform: uppercase;
}

.p-button:not(.p-button-link):hover {
  background: #1da3ba;
  border: 1px solid #1da3ba;
}

.p-button-label {
  font-weight: 600;
}

.dashboard-container {
  background-color: yellow;
  margin-top: 2rem;
}

.dashboard-paper {
  background-color: black;
  padding: 1rem;
}

.middle-content {
  padding: 50px 0;
  background-color: #fff;
}

.middle-content h2 {
  text-align: center;
  color: #353755;
  font-size: 1.65em;
  line-height: 35px;
  font-weight: 700;
  text-transform: capitalize;
  width: 100%;
  max-width: 786px;
  margin: 0 auto 40px;
}

.middle-content-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(540px, 1fr));
  gap: 20px;
  align-items: stretch;
  justify-content: flex-start;
}

.middle-content-wrapper.provider {
  display: block;
}

.middle-content-services {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.middle-content-services .event-service {
  width: 100%;
  max-width: 365px;
  border-radius: 12px;
  border: 0 !important;
  text-align: left;
  background: #ebebf1;
  transition: all 0.45s ease;
}

.middle-content-services .event-service.event-category {
  background: #fff;
}

.middle-content-services .event-service h3 {
  margin: 0;
}

.middle-content-services .event-service h5 {
  margin: 10px 0 0;
  font-size: 12px;
  line-height: 15px;
}

.middle-content-services .event-service .event-service-head,
.middle-content-services .event-service .event-service-head > div:not(.pi) {
  gap: 15px;
}

.middle-content-services .event-service .event-rsvp {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
}

.middle-content-sec {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: stretch;
  justify-content: flex-start;
}

.middle-content-sec.full {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  gap: 15px;
}

.middle-content-wrapper.provider .middle-content-sec {
  flex-direction: column;
}

.middle-content-wrapper.provider .middle-content-sec h5 {
  margin: 0;
}

.middle-content-wrapper > div > h3 {
  font-size: 1.45rem;
  font-weight: 600;
  margin: 0 0 15px;
  text-align: left;
  color: #0cc0df;
  position: absolute;
  top: -23px;
  background-color: #ffffff;
  padding: 5px 20px;
  border-radius: 10px;
  box-shadow: 0 -1px 2px 0px #ddd;
}

.middle-content-wrapper.provider > div > h3 {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  top: -15px;
  text-transform: uppercase;
}

.middle-content-wrapper > div {
  padding: 40px 15px 15px;
  background: #c2f6ff;
  border-radius: 12px;
  position: relative;
}

.middle-content-wrapper.provider > div {
  padding: 30px 15px 15px;
}

.middle-content-wrapper > div.middle-content-right {
  background: #fedbce;
}

.middle-content-wrapper > div.middle-content-right > h3 {
  color: #ff895d;
}

.middle-content-sec .card {
  color: inherit;
  text-decoration: none;
  width: 100%;
  /* width: calc(50% - 7.5px); */
  /* max-width: 350px; */
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #0cc0df;
  border-radius: 3px;
  transition: all 0.25s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  text-align: left;
  min-height: 105px;
}

.middle-content-sec .card > div:first-child {
  height: 100%;
}

.middle-content-sec > .card:hover {
  color: #fff;
  background-color: #0cc0df;
}

.middle-content-wrapper > div.middle-content-right .middle-content-sec .card {
  border: 1px solid #ff895d;
  cursor: pointer;
}

.middle-content-wrapper
  > div.middle-content-right
  .middle-content-sec
  .card:hover {
  background-color: #ff895d;
}

.middle-content-sec .card h4 {
  font-size: 1.15rem;
  line-height: 1.15;
  font-weight: 700;
  margin: 0 0 10px;
}

.middle-content-sec .card h5 {
  font-size: 0.85rem;
  font-weight: 600;
  margin: 0;
}

.middle-content-sec .card .desc {
  font-size: 0.85rem;
}

.middle-content-sec .card img {
  max-width: 100%;
  height: auto;
}

/* .middle-content-wrapper>* {
  flex: 1;
} */
.middle-content-wrapper > img {
  max-width: 570px;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  background: #0cc0df;
  color: #ffffff;
}

.middle-content .features {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  list-style: none;
  padding: 0;
  text-align: left;
  padding: 0;
  margin: 50px 0 0;
  font-size: 18px;
}

.middle-content .features li {
  width: calc(50% - 15px);
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
  align-items: baseline;
  justify-content: flex-start;
}

footer {
  background-color: #ebebf1;
}

.footer-content {
  padding: 40px 0 50px;
}

.footer-content-wrapper {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1fr;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  gap: 50px;
}

.footer-content-wrapper.equal {
  grid-template-columns: 1.75fr 1.75fr 1fr;
}

.footer-content-wrapper .section {
  text-align: left;
}

.footer-content-wrapper .section h3 {
  color: #353755;
  font-size: 25px;
  font-weight: 600;
  opacity: 1;
}

.event-form-content .section p,
.footer-content-wrapper .section p,
.footer-content-wrapper .section .footer-link {
  color: #353755;
  font-size: 16px;
  font-weight: 500;
}

.footer-content-wrapper .section p strong,
.footer-content-wrapper .section .footer-link strong {
  font-weight: 700;
  display: block;
}

.footer-content-wrapper .section p:last-child {
  margin-bottom: 0;
}

.event-form-content .section.contact p,
.footer-content-wrapper .section.contact p,
.footer-content-wrapper .section .footer-link {
  display: flex;
  gap: 15px;
  align-items: baseline;
  justify-content: flex-start;
}

.footer-content-wrapper .section ul {
  padding-left: 5px;
  list-style: none;
}

.footer-content-wrapper .section ul li {
  margin-bottom: 10px;
}

.footer-content-wrapper .section ul li a,
.footer-content-wrapper .section ul li span {
  color: #353755;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.copyright {
  font-size: 14px;
  text-align: center;
  background-color: #2c2a2b;
  color: #ffffff;
  padding: 10px 15px;
}

.main-container {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 146px);
  margin: 0;
  padding: 0;
  padding-top: 100px;
  z-index: 1;
}

.auth-dialog {
  width: calc(100% - 30px);
  max-width: 500px;
}

.auth-dialog.p-dialog .p-dialog-footer > button:last-child,
.auth-dialog.p-dialog .p-dialog-footer div > button:last-child {
  margin: 0;
}

.pwd-info.p-inline-message.p-inline-message-info {
  background: #fff;
  border: 1px solid #ff895d;
  color: #ff895d;
  align-items: flex-start;
}

.pwd-info.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #ff895d;
}

.pwd-info.p-inline-message .p-inline-message-text {
  font-size: 12px;
  font-weight: 600;
}

.newsletter {
  margin-bottom: 60px;
  padding: 25px;
  background-color: #0cc0df;
  border-radius: 8px;
}

.p-calendar {
  margin: 0;
  width: 100%;
}

.MuiFormHelperText-root {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 0.8571428571428571rem;
  line-height: 1.66;
  text-align: left;
}

.MuiFormHelperText-root.Mui-error {
  color: #d32f2f;
}

.p-datepicker {
  padding: 0;
}

.p-datepicker .p-datepicker-header {
  background-color: #0cc0df;
  color: #fff;
  padding: 0.5rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  color: #fff;
}

.p-datepicker table {
  margin: 0;
}

.p-datepicker .p-timepicker {
  border-top: 1px solid #353755;
}

/* .p-datepicker .p-timepicker button,
.p-datepicker table td>span {
  width: 1.65rem;
  height: 1.65rem;
} */

.p-datepicker table td > span {
  border-radius: 3px;
}

.p-datepicker table td.p-datepicker-today > span,
.p-datepicker table td > span.p-highlight {
  color: #0cc0df;
  background: #c2f6ff;
}

.p-datepicker .p-timepicker span {
  font-size: 1rem;
}

.p-datepicker .p-datepicker-buttonbar {
  justify-content: center;
  padding: 0.5rem;
  background-color: #0cc0df;
  color: #fff;
}

.p-datepicker .p-datepicker-buttonbar .p-button {
  padding: 0.45rem 1rem;
}

.footer-content-wrapper .section .footer-link {
  cursor: pointer;
  text-decoration: none;
  margin: 1em 0;
  justify-content: space-between;
  align-items: center;
}

.footer-content-wrapper .section ul li a:hover,
.footer-content-wrapper .section ul li span:hover,
.footer-content-wrapper .section .footer-link:hover {
  color: #0cc0df;
}

.p-button:focus {
  box-shadow: none;
}

.p-component.custom-dialog {
  width: calc(100% - 30px);
  max-width: 1024px;
  height: auto;
}

.p-component.custom-dialog .event-form-inner {
  margin: 0;
}

.p-component.custom-dialog .btns.group.full {
  display: flex;
  margin-bottom: 0;
  justify-content: space-between;
}

.p-component.custom-dialog .btns.group.full button {
  width: 50%;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #eef2ff;
  color: #0cc0df;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #0cc0df;
}

.cart-badge .MuiBadge-badge {
  background-color: #ff895d;
  color: #ffffff;
}

.create-event-cta {
  border-radius: 12px;
  overflow: hidden;
  text-align: center;
  background-color: #f8f8ff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04),
    6px 3px 14px 0px rgba(0, 0, 0, 0.04), 23px 12px 26px 0px rgba(0, 0, 0, 0.03),
    53px 27px 35px 0px rgba(0, 0, 0, 0.02),
    94px 48px 42px 0px rgba(0, 0, 0, 0.01);
}

.create-event-head {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  background-color: #0cc0df;
  padding: 10px 15px;
}

.create-event-head h3 {
  font-size: 1.0175rem;
  font-weight: 600;
  color: #fff;
  text-align: left;
}

.create-event-btn {
  padding: 15px 15px 20px;
}

.create-event-btn p {
  font-weight: 500;
  line-height: 150%;
  text-align: center;
}

.event-form-content {
  position: relative;
}

.event-form-head.banner > .event-service-head {
  width: 100%;
  border-radius: 12px;
  border: 0 !important;
  text-align: left;
  background: #f8f8ff;
  transition: all 0.45s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04),
    6px 3px 14px 0px rgba(0, 0, 0, 0.04), 23px 12px 26px 0px rgba(0, 0, 0, 0.03),
    53px 27px 35px 0px rgba(0, 0, 0, 0.02),
    94px 48px 42px 0px rgba(0, 0, 0, 0.01);
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.event-form-head.banner > .event-service-head:hover {
  background-color: #c2f6ff;
}

.event-form-content.event-service-content > .event-service-head h3 {
  font-size: 1.175rem;
  font-weight: 600;
}
.event-form-content.event-service-content .event-service-data p:last-child {
  margin-bottom: 0;
}
.event-form-content.event-service-content
  .event-service-data
  .event-service-list
  .event-service {
  border: 0 !important;
  padding: 20px;
  background: #ebebf1;
}

.event-form-content h2 {
  font-size: 1.17em;
  line-height: 1.5;
}

.event-form-content.event-service-content > .event-service-data .sub-head {
  font-size: 0.875em;
  line-height: 1.5;
  color: #7a7f85;
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
}

.event-form-content.event-service-content > .event-service-data h2 {
  margin: 1em 0;
  font-size: 1.17rem;
  line-height: 1.5;
  text-align: center;
}

.event-form-content.event-service-content > .event-service-data blockquote {
  margin: 2em 0;
  font-style: italic;
  text-align: center;
}

.event-form-content.event-service-content
  > .event-service-data
  .middle-content-services {
  margin-bottom: 30px;
}

.event-form-content.event-service-content
  > .event-service-data
  .middle-content-services
  .event-service {
  max-width: 264px;
}

.event-form-content.event-service-content
  > .event-service-data
  .middle-content-services
  .event-service.half {
  max-width: 406px;
}

.event-form-content.event-service-content
  > .event-service-data
  .middle-content-services
  .event-service.half
  .event-service-inner-head {
  margin: 0;
}

.event-form-content.event-service-content
  > .event-service-data
  .middle-content-services
  .event-service.half
  .event-service-inner-head::after {
  content: "";
  width: 100px;
  height: 3px;
  background-color: #353755;
  display: block;
  margin-top: 10px;
}

.event-form-content.event-service-content
  > .event-service-data
  .middle-content-services
  .event-service.full {
  max-width: 100%;
}

.middle-content-services .event-service .event-service-list-item h5 {
  font-size: clamp(16px, 1.094vw, 24px);
}

.middle-content-services .event-service .event-service-list-item p {
  color: #7a7f85 !important;
  margin-top: 0;
  font-size: clamp(13px, 0.833vw, 22px);
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 150%;
}

.middle-content-services .event-service .event-service-list-item:last-child p {
  margin-bottom: 0;
}

.testimonials {
  border-radius: 12px;
  border: 0 !important;
  text-align: left;
  background: #f8f8ff;
  padding: 40px 40px 20px;
  margin-bottom: 0;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04),
    6px 3px 14px 0px rgba(0, 0, 0, 0.04), 23px 12px 26px 0px rgba(0, 0, 0, 0.03),
    53px 27px 35px 0px rgba(0, 0, 0, 0.02),
    94px 48px 42px 0px rgba(0, 0, 0, 0.01);
}

.testimonials .p-carousel-item h3 {
  font-size: 1.125rem;
  font-weight: 400;
  text-align: center;
  font-style: italic;
}
.testimonials .p-carousel-item h5 {
  font-size: 1.095rem;
  font-weight: 600;
  color: #0cc0df;
  text-align: center;
}
.p-inline-message.p-inline-message-success {
  background: #c2f6ff;
  border: solid #0cc0df;
  border-width: 0px;
  color: #0cc0df;
}

.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #0cc0df;
}

.p-inline-message.p-inline-message-error {
  background: #fedbce;
  border: solid #ff895d;
  border-width: 0px;
  color: #ff895d;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #ff895d;
}

@media (max-width: 763px) {
  .main-container {
    padding-top: 120px;
  }

  .main-container.no-auth {
    padding-top: 70px;
  }

  h2 {
    font-size: 1.15rem;
  }

  .event-form h2 {
    text-align: center;
  }

  .p-button {
    padding: 0.75rem;
    font-size: 0.95rem;
  }

  .frontend-container {
    padding: 0 15px;
  }

  .middle-content-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 45px;
  }

  .middle-content-sec .card {
    width: 100%;
    flex-direction: row;
    align-items: center;
  }

  .middle-content h2 {
    font-size: 1.45rem;
  }

  .footer-content-wrapper {
    grid-template-columns: 1fr;
  }

  .newsletter button .MuiButton-endIcon {
    margin: 0;
  }

  .footer-content-wrapper .section h3 {
    font-size: 1.45rem;
  }

  .footer-content-wrapper .section p,
  .footer-content-wrapper .section ul li {
    font-size: 0.95rem;
  }
  .p-component.custom-dialog {
    max-height: 100%;
    border-radius: 0;
  }

  .p-component.custom-dialog .p-dialog-content {
    overflow-y: overlay;
  }

  .event-form-content.event-service-content
    > .event-service-data
    .middle-content-services
    .event-service,
  .event-form-content.event-service-content
    > .event-service-data
    .middle-content-services
    .event-service.half {
    max-width: 100%;
  }
}

.box {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
