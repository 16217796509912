.background-video {
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure video fills the container while maintaining aspect ratio */
  z-index: -1;
}

.search-bar-container {
  position: absolute;
  top: 12%;
  /* Adjust for better responsiveness */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  /* Reduce width for larger screens to avoid covering the video */
  display: flex;
  justify-content: center;
}

.search-input {
  width: 100%;
  padding: 12px;
  font-size: 1.2rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.7);
  /* Transparent effect */
}

/* Event list styles */
.event-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-item {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  margin: 10px;
  width: 80%;
  text-align: center;
  border-radius: 5px;
}

@media (max-width: 1000px) {

  /* For tablets or smaller desktops */
  .search-bar-container {
    top: 12%;
    /* Adjust top position */
    width: 80%;
    /* Reduce width for smaller devices */
  }

  .search-input {
    font-size: 1rem;
    padding: 10px;
    /* Reduce padding on smaller screens */
  }

  .event-item {
    width: 90%;
  }
}

@media (max-width: 600px) {

  .background-video {
    object-fit: cover;
    /* Ensure video scales properly */
  }

  .search-bar-container {
    top: 10%;
    /* Reduce top position for mobile */
    width: 90%;
  }

  .search-input {
    font-size: 0.9rem;
    /* Smaller font size for mobile */
    padding: 8px;
  }

  .event-item {
    width: 95%;
    font-size: 0.9rem;
    /* Reduce font size for event items */
  }
}