
  .signup-card {
    background: white; /* Slight transparency for the card */
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .login-input.checkbox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  .login-input.checkbox label{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .signup-button {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #0cc0df;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .signup-button:hover {
    background-color: #1da3ba;
  }
  
  

  