.account-content {
  flex-grow: 1;
  display: flex;
  justify-content: start;
  text-align: start;
  flex-direction: column;
  align-items: right;
  margin-top: 0px;
  color: #353755;
  line-height: normal;
  visibility: visible;
  padding-left: 0;
  background-color: rgb(255, 255, 255);
}

.account-d {
  display: flex;
  justify-content: top;
  flex-direction: row;
  padding: 0 0 4px 0;
  border-bottom: 1px solid #eee;
  text-align: start;
  color: #353755;
  text-decoration: none;
}

.account-dialog-overlay {
  position: absolute;
  top: 45px;
  /* Just below the AppBar */
  right: 0;
  width: 250px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  /* To ensure it shows above other elements */
  padding: 15px;
}
.services-dialog{
  position: absolute;
  top: 40px
}

.account-d {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 4px 0;
  margin: 4px 0;
  border-bottom: 1px solid #eee;
  text-align: start;
  color: #353755;
  font-size: 0.95rem;
  font-weight: 600;
}
.account-d:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.account-d > a{
  color: #353755;
  text-decoration: none;
}

.account-header {
  display: flex;
  justify-content: end;
}

.close-button {
  margin: 0;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.account-details {
  margin: 0;
}

.logout-button {
  margin-top: 15px;
  width: 100%;
  padding: 10px;
  background-color: #0cc0df;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .account-dialog-overlay {
    width: 35%;
    right: 0;
    top: 64px;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .account-dialog-overlay {
    width: 50%;
    right: 0;
    top: 64px;
    padding: 10px;
  }

  .account-header h2 {
    font-size: 18px;
  }

  .logout-button {
    padding: 8px;
  }
}