  /* Login container */
  .login-container {
    background: white; /* Slight transparency for the card */
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  /* Login card content */
  .login-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-input {
    width: 100%;
    padding: 8.5px 14px;
    margin: 5px 0 2px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-size: 1.1428571428571428rem;
    line-height: 1.4375em;
    color: rgba(0, 0, 0, 0.6);
  }
  .login-input:focus,
  .login-input:focus-visible{
    outline: 0;
  }
  .login-input:focus::-webkit-input-placeholder,
  .login-input:focus-visible::-webkit-input-placeholder {
      opacity: 0;
  }
 
  .login-button {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 10px;
    background-color: #0cc0df;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }

  .login-button:hover {
    background-color: #1da3ba;
  }
  
  .login-button:disabled {
    background-color: #ccc;
  }
  
  .signup {
    margin-top: 15px;
    text-align: center;
    color: #0cc0df;
    cursor: pointer;
    font-weight: 700;
  }

  .login-button-secondary {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #e3f2fd; /* Light blue/sky background */
    color: #807d7d; /* Orange font color */
    border: 2px solid #ff895d; /* Orange border to match the font */
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .login-button-secondary:hover {
    background-color: #bbdefb; /* Slightly darker blue on hover */
    color: #807d7d; /* Slightly darker orange font color */
  }
  
  .login-button-secondary:disabled {
    background-color: #f0f0f0; /* Light grey for disabled state */
    color: #ccc; /* Grey text for disabled state */
    cursor: not-allowed;
  }
  
  

  /* Common styles for text buttons */
.text-button {
  background: none;
  border: none;
  color: #0cc0df;
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none; /* Remove underline for links */
}

.text-button:hover {
  color: #1da3ba; /* Slightly darker blue on hover */
}

/* Container for options with space between */
.login-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.login-options .text-button{
  padding: 0;
}

/* Additional single-button alignment */
.login-options.single {
  justify-content: flex-end;
}

/* Mobile responsive behavior */
@media screen and (max-width: 600px) {
  .login-options {
    align-items: flex-start;
    gap: 10px;
  }
}

