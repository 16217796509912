.cart-dialog {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100vh;
  background: #fff;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 0px;
  display: flex;
  flex-direction: column;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.cart-dialog.open {
  right: 0px;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #0cc0df;
}

.cart-header h2 {
  margin: 0;
  line-height: 1;
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
}

.cart-header .close-button {
  line-height: 1;
  color: #fff;
  font-size: 25px;
}

.cart-items {
  flex-grow: 1;
  overflow-y: auto;
}

.cart-item {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px dotted #ddd;
  text-align: left;
  gap: 15px;
  color: #353755;
}

.cart-item h3 {
  font-size: 1.125rem;
  line-height: 21px;
  margin: 0 0 10px;
}
.cart-item h3.amount {
  margin: 0;
}
.cart-item h4 {
  font-size: 0.75rem;
  line-height: 18px;
  margin: 0;
  color: #8f8f8f;
  font-weight: 500;
}

.cart-item .label {
  font-size: 12px;
  color: #8f8f8f;
}
.cart-item .remove{
  line-height: 1;
  padding: 5px;
  cursor: pointer;
}
.cart-item .remove i{
  font-size: 0.75rem;
  color: red;
}

.cart-item:last-child {
  border-bottom: 0;
}

.cart-item.full {
  height: 100%;
  align-items: center;
  justify-content: center;
}

.cart-total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 15px;
  border-top: 1px solid #ddd;
  color: #353755;
}

.cart-footer {
  border-top: 1px solid #ddd;
  padding: 10px 15px;
}

.cart-footer .btns.group {
  display: flex;
  justify-content: center;
}

.cart-footer .btns.group>button {
  width: 50%;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
}

/* Responsive design */
@media (max-width: 768px) {
  .cart-dialog{
    width: 100vw;
    right: -100vw;
  }
}