.placeholder {
  width: 100%;
  min-height: 24px;
  background-color: #f2f2f2;
  border-radius: 6px;
}

.profile-item.head {
  font-size: 18px;
  font-weight: 700;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px dotted;
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
.profile-item.head > span {
  gap: 10px;
}
.verify {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  gap: 5px;
}
.verify .check {
  display: inline-block;
  padding: 4px 8px;
  background-color: #0cc0df;
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
  border-radius: 10px;
  line-height: 1;
}
.service-type {
  background: #f8f8f8;
  border-radius: 6px;
  position: relative;
}
.services-selection {
  position: relative;
  /* display: grid;
    grid-template-columns: 65% 35%; */
  margin-top: 20px;
}
.profile-card-panel .loading,
.services-selection .loading {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.65);
  z-index: 1;
}
.p-dialog-content {
  overflow-y: initial;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0;
}
.p-dialog.p-confirm-dialog .p-dialog-footer {
  text-align: center;
}
.p-dialog.p-confirm-dialog .p-dialog-footer .p-button {
  padding: 8px;
  width: 90px;
  border-radius: 5px;
  text-align: center;
}
.p-dialog.p-confirm-dialog .p-dialog-footer .p-button.p-button-danger {
  background-color: #ff895d;
  border-color: #ff895d;
}

.add-address.p-dialog {
  max-height: 100%;
}
.add-address.p-dialog .p-dialog-header .p-dialog-title {
  max-width: none;
}
.action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.25s ease;
}
.action svg {
  color: #ffffff;
  width: 20px;
  height: 20px;
}
.action.edit {
  background-color: #0cc0df;
}
.action.edit:hover {
  background-color: #1da3ba;
  box-shadow: 0 0 1px 2px #c2f6ff;
}
.action.delete {
  background-color: #ff895d;
}
.action.delete:hover {
  background-color: #f16a39;
  box-shadow: 0 0 1px 2px #fedbce;
}

.serviceForm {
  width: 100%;
  /* max-width: 450px; */
}
.serviceForm h2 {
  margin-bottom: 15px;
  color: #0cc0df;
  font-weight: 700;
}
.user-profile {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 24px;
  margin: 0 1px;
}
/* .user-profile .profile-card{
    flex: 1 1;
    min-width: calc(50% - 12px);
} */
.profile-card-panel {
  position: relative;
}
.profile-card-panel-header {
  padding: 12px 15px;
  font-size: 1rem;
  font-weight: 700;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-card-panel-header > label {
  min-height: 38px;
  line-height: 38px;
}
.profile-card-panel-body {
  padding: 20px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04),
    6px 3px 14px 0px rgba(0, 0, 0, 0.04), 23px 12px 26px 0px rgba(0, 0, 0, 0.03),
    53px 27px 35px 0px rgba(0, 0, 0, 0.02),
    94px 48px 42px 0px rgba(0, 0, 0, 0.01);
  background: #f8f8ff;
  border-radius: 12px;
}
.profile-item {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 30px 0 0;
}
.profile-item:last-child {
  padding-bottom: 30px;
}
.profile-item .picon {
  width: 3.5em;
  height: 3.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.profile-item .picon.bio {
  background: #c2f6ff;
}
.profile-item .picon.con {
  background: #fedbce;
}
.profile-item .picon.eml {
  background: #d3d3d3;
}
.profile-item .picon.loc {
  background: #c2f6ff;
}
.profile-form {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding-top: 30px;
}
.profile-form > * {
  flex: 1 1;
  min-width: calc(50% - 5px);
}
.MuiTableRow-root.td-header-row > td .td-header {
  display: none;
}
#AddressInfoForm {
  width: 90vw;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 30px;
}
@media (max-width: 763px) {
  .MuiTableRow-root.td-header-row {
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid #e6e6e6;
  }
  .MuiTableRow-root.td-header-row:last-child {
    border-bottom: 0;
  }
  .MuiTableRow-root.td-header-row > td {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 16px;
  }
  .MuiTableRow-root.td-header-row > td:first-child {
    color: #ffffff;
    background-color: #0cc0df;
    font-weight: 700;
  }
  .MuiTableRow-root.td-header-row:last-child > td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
  .MuiTableRow-root.td-header-row > td .td-header {
    display: block;
    font-weight: 700;
    width: 50%;
  }
  .MuiTableRow-root.td-header-row > td.td-header-top {
    flex-direction: column;
    align-items: flex-start;
    white-space: unset;
  }
  .user-profile {
    grid-template-columns: 100%;
    text-align: left;
  }
  #AddressInfoForm {
    width: 100%;
    grid-template-columns: 100%;
  }
  #AddressInfoForm > .MuiBox-root > div:first-child {
    height: 300px !important;
  }
}
