.soon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  color: #000;
  /* background: #0cc0df;
  background: linear-gradient(159deg, rgba(12, 192, 223, 1) 26%, #ff895d 63%); */
}

.logo-bg {
  background-color: #fff;
  border-radius: 25px;
}

.soon-content {
  text-transform: uppercase;
  text-align: center;
}
