.hdwork {
  width: 100%;
  padding: 50px 0;
}

.hdwork h1 {
  margin-top: 0;
  margin-bottom: 30px;
  color: #353755;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
}

.diagram-container {
  margin-top: 80px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  justify-content: center;
  align-items: stretch;
  width: 100%;
  gap: 30px;
  counter-reset: card-counter;
}

.card-container {
  counter-increment: card-counter;
}

.card-container>.p-card::before {
  content: counter(card-counter);
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FF895D;
  font-weight: 700;
  font-size: 30px;
  line-height: 58px;
  border: 10px solid #ffffff;
  color: #fff;
}

.card-container {
  background: #f7f7f7;
  color: #4b5563;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.p-card {
  background: #f7f7f7;
  position: relative;
  box-shadow: none;
  border-radius: 0;
}

.p-card .p-card-title {
  font-weight: 600;
  color: #353755;
}

.p-card-subtitle {
  margin-bottom: 20px;
}

.p-card-body {
  padding: 50px 30px 30px;
}

.p-card-content p {
  margin-bottom: 0;
  font-size: 14px;
}
@media (max-width: 700px) {
  .diagram-container{
    gap: 50px;
  }
}