@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap");

.video-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 100px);
  overflow: hidden;
  margin-top: 100px;
  z-index: 1;
}

.video-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 1;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

/* Container that holds both message and form */
/* .video-container-contents {
  position: relative;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
} */
.video-container-contents {
  position: relative;
  display: flex;
  z-index: 2;
  height: 100%;
}

.video-container-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  gap: 60px;
}

/* Left side message */
.video-container-inner .message {
  font-size: 35px;
  text-transform: uppercase;
  line-height: 42px;
  font-weight: 700;
  text-align: left;
  font-family: "League Spartan", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.video-container-inner .message a {
  display: inline-block;
  position: relative;
  color: #0cc0df;
  cursor: pointer;
  text-shadow: 0 0 3px #000;
  transition: all 0.25s ease;
  text-decoration: none;
}
.video-container-inner .message a:hover {
  color: #ff895d;
}
/* .video-container-inner .message span::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #fff;
} */

/* Form styling */
.event-create-form {
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-create-form form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.event-create-form input,
.event-create-form button {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 16px;
}

.event-create-form button {
  background-color: #ff6b6b;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.event-create-form button:hover {
  background-color: #ff4c4c;
}

/* Blockquote styling */
.custom-blockquote {
  font-size: 22px;
  position: relative;
  margin: 0;
  color: #ffffff;
}

.custom-blockquote p {
  margin: 0;
  font-style: italic;
  color: #f8f9fa;
  line-height: 1.2;
  text-indent: 1rem;
}

/* .quote-icon {
  font-size: 2.5rem;
  color: #FF895D;
  position: absolute;
  top: -1rem;
  left: -1rem;
} */

/* Align company name (Thurent) to the right */
/* .blockquote-footer {
  font-size: 0.9rem;
  color: #FF895D;
  margin-top: 10px;
  text-align: right;
} */

/* .blockquote-footer cite {
  font-style: normal;
  font-weight: bold;
  color: #FF895D;
  font-size: 1.1rem;
} */

/* Divider */
.divider {
  margin: 0 10px;
  width: 2px;
  /* Vertical line thickness */
  background-color: rgba(255, 255, 255, 0.5);
  height: 80%;
  /* Adjust height of the divider */
}

/* Divider for smaller screens */
@media (max-width: 1000px) {
  .divider {
    margin: 10px 0;
    width: 80%;
    /* Full width for horizontal divider */
    height: 2px;
    /* Horizontal line thickness */
    margin: 10px 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

/* Responsive behavior for small screens (below 1000px) */
@media (max-width: 1000px) {
  .video-container-inner {
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding: 50px 0;
  }

  .event-create-form {
    width: 100%;
  }

  .custom-blockquote {
    font-size: 20px;
    /* text-align: center; */
  }

  /* .blockquote-footer {
    text-align: center;
  } */
}

@media (max-width: 763px) {
  .video-container {
    min-height: calc(100vh - 70px);
    margin-top: 70px;
  }
  .video-container-inner {
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 25px 0;
    gap: 25px;
  }

  .video-container-inner .message {
    font-size: 1.45rem;
    font-weight: 700;
    text-align: center;
  }
  .video-container-inner .message span {
    font-size: 1.25rem;
  }

  .event-create-form {
    width: 100%;
  }

  .custom-blockquote {
    font-size: 16px;
    /* text-align: center; */
  }

  /* .blockquote-footer {
    text-align: center;
  } */
}
